import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/chevron-right-gray.svg'


const _withScopeId = n => (_pushScopeId("data-v-5f20e05a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "builder-controls"
}
const _hoisted_2 = { class: "secondary-buttons-wr" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["disabled"]
const _hoisted_6 = {
  key: 0,
  class: "chevron-right",
  src: _imports_0
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.props.lot)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          ($props.lot.status === $setup.LotStatus.AVAILABLE && $setup.props.isInitialHoldDisabled)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: _normalizeClass(["button secondary", { disabled: _ctx.disabled }]),
                onClick: $setup.reserve,
                disabled: _ctx.disableButton
              }, _toDisplayString($setup.isDesktop ? "Reserve Lot" : "Reserve lot"), 11, _hoisted_3))
            : _createCommentVNode("", true),
          ($props.lot.status === $setup.LotStatus.AVAILABLE && !$setup.props.isInitialHoldDisabled)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                class: _normalizeClass(["button secondary", { disabled: _ctx.disabled }]),
                onClick: $setup.hold,
                disabled: _ctx.disableButton
              }, _toDisplayString($setup.isDesktop ? "Request a Hold" : "Request a hold"), 11, _hoisted_4))
            : _createCommentVNode("", true),
          _createElementVNode("button", {
            class: _normalizeClass(["button secondary", { 'mt-0': $props.lot.status !== $setup.LotStatus.AVAILABLE }]),
            onClick: $setup.toContact
          }, _toDisplayString($setup.isDesktop ? "Make Enquiry" : "Make enquiry"), 3)
        ]),
        ($props.landOptionsAvailable)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: _normalizeClass(["button primary customise", { disabled: _ctx.disabled }]),
              onClick: $setup.onRequestQuote,
              disabled: _ctx.disableButton
            }, [
              _createTextVNode(_toDisplayString($setup.isDesktop ? "Create Home & Land Package" : "Create a Home & Land Package"), 1),
              (!$setup.isDesktop)
                ? (_openBlock(), _createElementBlock("img", _hoisted_6))
                : _createCommentVNode("", true)
            ], 10, _hoisted_5))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}